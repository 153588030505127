<template>
  <v-col class="WindowReservierung">
    <div><Standrohrdaten ref="Standrohr" /></div>
    <div><Kundendaten ref="Kunden" /></div>
    <div v-if="store.state.Bankenmodul == true"><Bankdaten ref="Bank" /></div>
    <div><WeitereVertragsdaten ref="WeitereVertrag" /></div>

    <v-row>
      <v-col class="width:40% hidden-xs">
        <v-form ref="checkAGBDatenschutz">
          <v-row class="ml-5">
            <v-checkbox
              v-if="Vorhanden('AGB') == true"
              v-model="AgbAceppt"
              color="primary"
              density="compact"
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <a
                  class="AGBColor"
                  :href="$store.state.AGBpath"
                  target="_blank"
                  >{{ store.state.AGBDisplayname }}</a
                >
                &nbsp; akzeptieren
              </template>
            </v-checkbox>
          </v-row>

          <v-row class="ml-5 hidden-xs">
            <v-checkbox
              v-if="Vorhanden('Datenschutzerklaerung') == true"
              v-model="DatenschutzerklaerungAccept"
              color="primary"
              density="compact"
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <a
                  class="AGBColor"
                  :href="$store.state.Datenschutzpath"
                  target="_blank"
                  >{{ store.state.DatenschutzDisplayname }}</a
                >
                &nbsp; akzeptieren
              </template>
            </v-checkbox>
          </v-row>

          <v-row class="ml-5 hidden-xs">
            <v-checkbox
              v-if="Vorhanden('SontigesDookument1') == true"
              v-model="SontigesDokument1Accept"
              color="primary"
              density="compact"
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <a
                  class="AGBColor"
                  :href="$store.state.SonstigesDokumentpath"
                  target="_blank"
                  >{{ store.state.SonstigesDokumentDisplayname }}</a
                >
                &nbsp; akzeptieren
              </template>
            </v-checkbox>
          </v-row>
        </v-form>
      </v-col>

      <v-col class="pr-4 width:40%; hidden-xs">
        <v-row>
          <v-btn
            v-if="Vorhanden('SonstigeButtons') == true"
            class="mt-2 mr-4"
            style="width: 650px"
            @click="openPreise()"
          >
            Preisblatt Wasser
          </v-btn>
        </v-row>

        <v-row>
          <v-btn
            v-if="Vorhanden('SonstigeButtons') == true"
            class="mt-2 mr-4"
            style="width: 650px"
            @click="openAnleitung()"
          >
            Bedienungsanleitung Standrohr
          </v-btn>
        </v-row>
      </v-col>

      <v-col class="ma-2 hidden-sm-and-up">
        <v-checkbox
          v-if="Vorhanden('AGB') == true"
          v-model="AgbAceppt"
          color="primary"
          density="compact"
          :rules="[rules.required]"
        >
          <template v-slot:label>
            <a class="AGBColor" :href="$store.state.AGBpath" target="_blank">{{
              store.state.AGBDisplayname
            }}</a>
            &nbsp; akzeptieren
          </template>
        </v-checkbox>

        <v-checkbox
          v-if="Vorhanden('Datenschutzerklaerung') == true"
          v-model="DatenschutzerklaerungAccept"
          color="primary"
          density="compact"
          :rules="[rules.required]"
        >
          <template v-slot:label>
            <a
              class="AGBColor"
              :href="$store.state.Datenschutzpath"
              target="_blank"
              >{{ store.state.DatenschutzDisplayname }}</a
            >
            &nbsp; akzeptieren
          </template>
        </v-checkbox>

        <v-checkbox
          v-if="Vorhanden('SontigesDookument1') == true"
          v-model="SontigesDokument1Accept"
          color="primary"
          density="compact"
          :rules="[rules.required]"
        >
          <template v-slot:label>
            <a
              class="AGBColor"
              :href="$store.state.SonstigesDokumentpath"
              target="_blank"
              >{{ store.state.SonstigesDokumentDisplayname }}</a
            >
            &nbsp; akzeptieren
          </template>
        </v-checkbox>

        <v-btn
          class="mb-2"
          v-if="Vorhanden('SonstigeButtons') == true"
          block
          @click="openPreise()"
        >
          Preisblatt Wasser
        </v-btn>

        <v-btn
          v-if="Vorhanden('SonstigeButtons') == true"
          block
          @click="openAnleitung()"
        >
          Bedienungsanleitung Standrohr
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="save">
      <v-card>
        <v-card-title class="HeaderTitle">
          <v-toolbar color="primary" density="compact" flat>
            <v-toolbar-title class="textcolor"> Erfolgreich </v-toolbar-title>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-col>
            <v-row class="ma-2" align="center" justify="center">
              <v-icon color="green" size="100"> mdi-check-circle </v-icon>
            </v-row>
            <v-row class="ma-2" align="center" justify="center">
              {{ "Ihre Reservierung wurde übermittelt" }}
            </v-row>
            <v-row class="ma-2" align="center" justify="center">
              <v-btn @click="SaveVertragMeldung()"> Ok </v-btn>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="Fehler">
      <Error />
    </v-dialog>

    <v-row class="mt-6 mr-2 ml-2 mb-2 hidden-xs">
      <v-btn
        :disabled="aktive"
        block
        @click="saveVertrag()"
        style="width: 100%"
      >
        Reservierungsanfrage absenden
      </v-btn>
    </v-row>

    <v-row class="ml-2 mr-2 mb-2 hidden-sm-and-up">
      <v-btn :disabled="aktive" block @click="saveVertrag()">
        Reservierungsanfrage absenden
      </v-btn>
    </v-row>
  </v-col>

  <v-dialog v-model="showNichtAllesAusgefuellt">
    <v-card>
      <v-card-title class="HeaderTitle">
        <v-toolbar color="primary" density="compact" flat>
          <v-toolbar-title class="textcolor">
            Nicht alle Pflichtfelder wurden korrekt ausgefüllt.
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        Nicht alle Pflichtfelder wurden korrekt ausgefüllt. Bitte überprüfen sie
        alle Felder nochmal ob sie diese korrekt ausgefüllt haben.
      </v-card-text>
      <v-btn class="ma-2" @click="showNichtAllesAusgefuellt = false">
        ok
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="save"> </v-dialog>
</template>


<script setup>
import Error from "@/components/main/Errors.vue";
import Standrohrdaten from "./Pages/StandrohrDaten.vue";
import Kundendaten from "./Pages/KundenDaten.vue";
import Bankdaten from "./Pages/BankDaten.vue";
import WeitereVertragsdaten from "./Pages/WeitereVertragsdaten.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import dbhelper from "@/plugins/dbHelper.js";

const NeuerVertrag = "NeuerVertrag";

const store = useStore();

var Standrohr = ref(null);
var Kunden = ref(null);
var Bank = ref(null);
var WeitereVertrag = ref(null);
var checkAGBDatenschutz = ref(null);

var AgbAceppt = ref(false);
var DatenschutzerklaerungAccept = ref(false);
var SontigesDokument1Accept = ref(false);

var showNichtAllesAusgefuellt = ref(false);
var save = ref(false);
var aktive = ref(false);
var Fehler = ref(false);
var Fehlermeldung = ref("");

var AGBfile = ref(null);
var Datenschutzerklaerungfile = ref(null);

var rules = ref({
  required: (value) => {
    return !!value || "Muss akzeptiert werden, um fortzufahren.";
  },
});

function showAGB() {
  var file = "../../assets/AGB.png";
  var url = "";

  url = URL.createObjectURL(file);

  var link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  window.open(link);
}

async function saveVertrag() {
  // erstelle Hier den vertrag
  var checkboxtest = await checkAGBDatenschutz.value.validate();
  var check = await checkFelder();

  if (checkboxtest.valid == true) {
    if (check == true) {
      aktive.value = true;
      var Vertrag = {};
      Vertrag.Bauform = store.state.Bauform;
      Vertrag.Konzessionsgebiet = store.state.Konzessionsgebiet;
      Vertrag.Einsatzstelle = store.state.Einsatzstelle;
      Vertrag.VoraussichtlicheDauer = store.state.VoraussichtlicheDauer;
      Vertrag.Langzeitmiete = store.state.Langzeitmiete;
      Vertrag.Zahlungsart = store.state.Zahlungsart;
      Vertrag.AusgabeName = store.state.AusgabeName;
      Vertrag.AusgabeVorname = store.state.AusgabeVorname;

      Vertrag.Kundennummer = store.state.Kundennummer;
      Vertrag.Firma = store.state.Firma;
      Vertrag.Anrede = store.state.Anrede;
      Vertrag.Vorname = store.state.Vorname;
      Vertrag.Nachname = store.state.Nachname;
      Vertrag.Strasse = store.state.Strasse;
      Vertrag.Hausnummer = store.state.Hausnummer;
      Vertrag.HausNrErg = store.state.HausNrErg;
      Vertrag.Telefon = store.state.Telefon;
      Vertrag.Telefon2 = store.state.Telefon2;
      Vertrag.EMail = store.state.EMail;
      Vertrag.Plz = store.state.Plz;
      Vertrag.Ort = store.state.Ort;
      Vertrag.Land = store.state.Land;

      Vertrag.BankKontoinhaber = store.state.Bank.Kontoinhaber;
      Vertrag.BankKontonummer = store.state.Bank.Kontonummer;
      Vertrag.BankName = store.state.Bank.Name;
      Vertrag.BankIBAN = store.state.Bank.IBAN;
      Vertrag.BankBIC = store.state.Bank.BIC;
      Vertrag.BankBankleitzahl = store.state.Bank.Bankleitzahl;

      Vertrag.ServicePoint = store.state.ServicePoint;
      Vertrag.Einsatzort = store.state.Einsatzort;
      Vertrag.Entnahmezweck = store.state.Entnahmezweck;
      Vertrag.MitAbwasser = store.state.MitAbwasser;
      Vertrag.StueckHydrantenschluessel = store.state.StueckHydrantenschluessel;
      Vertrag.Bemerkungen = store.state.Bemerkungen;

      var response = await dbhelper.NeuerVertrag(NeuerVertrag, Vertrag);
      if (response.result.erfolgJN == "J") {
        save.value = true;
        resetFelder();
        aktive.value = false;
      } else {
        store.state.FehlerCode = 202;
        store.state.FehlerText = response.result.fehlertext;
        Fehler.value = true;
      }
    }
  } else {
    showNichtAllesAusgefuellt.value = true;
  }
}

function SaveVertragMeldung() {
  save.value = false;
}

function Vorhanden(Feld) {
  var FeldNichtvorhandewn = false;
  store.state.FelderNichtAnzeigen.forEach((element) => {
    if (element == Feld) FeldNichtvorhandewn = true;
  });
  if (FeldNichtvorhandewn == true) return false;
  else return true;
}

async function checkFelder() {
  var result = true;
  var Standrohrdaten = await Standrohr.value.checkvalidate();
  if (store.state.Bankenmodul == true)
    var Bankdaten = await Bank.value.checkvalidate();
  if (store.state.VerwaltungEinschalten == false)
    var Kundendaten = await Kunden.value.checkvalidate();

  var WeitereVertragsdaten = await WeitereVertrag.value.checkvalidate();

  if (Standrohrdaten == false) {
    result = false;
    return result;
  }
  if (Bankdaten == false) {
    result = false;
    return result;
  }
  if (Bankdaten == false) {
    result = false;
    return result;
  }
  if (Kundendaten == false) {
    result = false;
    return result;
  }
  if (WeitereVertragsdaten == false) {
    result = false;
    return result;
  }

  return result;
}

function resetFelder() {
  Standrohr.value.resetFelder();
  if (store.state.Bankenmodul == true) Bank.value.resetFelder();
  Kunden.value.resetFelder();
  WeitereVertrag.value.resetFelder();
}

function openPreise() {
  var file = store.state.PreisFile;
  window.open(file);
}

function openAnleitung() {
  var file = store.state.AnleitungFile;
  window.open(file);
}
</script> 

<style scoped>
.AGBColor {
  color: red;
}
.ContainerAkzeptieren {
  width: 1px !important;
}
</style>

